<template>
    <div v-if="events && !loading">
        <zw-events-new :events="events"
                       :callback="callback"
                       :customerId="customerId"
                       :customerForm="customerForm"
        ></zw-events-new>
    </div>
</template>

<script>

import ZwEventsNew from "@/components/ZwEventsNew.vue";

export default {
    name: 'event-tab',
    components: {ZwEventsNew},
    props: {
        customerId: {
            type: Number,
        },
        customerForm: null,
        callback: null,
        types: null,
    },
    data() {
        return {
            loading: true,
            events: null,
        }
    },
    methods: {},
    created() {
        this.loading = true
        window.axios.post(window.apiUrl + '/customer/events/' + this.customerId,
            {'event_types': this.types},
            {'skip_loading': false})
            .then(response => {
                this.events = response.data.events
            })
            .finally(() => {
                this.loading = false
            })
    }
}
</script>